import https from "./https";
import store from "@/store";

const groupbuy = {
  getGroupBuyProducts(code) {
    const organization = store.state.general.organization;
    return https.get(
      `admin/organizations/${organization.id}/groupbuying-products?code=${code}`
    );
  },

  getMainGroupbuyList(params) {
    const organization = store.state.general.organization;
    // const searchParams = new URLSearchParams(params);

    return https.get(
      `admin/organizations/${organization.id}/main-groupbuyings`, {params}
    );
  },

  createGroupbuy(params) {
    const organization = store.state.general.organization;
    return https.post(
      `admin/organizations/${organization.id}/main-groupbuyings`,
      {
        ...params,
      }
    );
  },

  getGroupbuying(groupbuying) {
    const organization = store.state.general.organization;
    return https.get(
      `admin/organizations/${organization.id}/main-groupbuyings/${groupbuying}`
    );
  },

  updateGroupbuying(groupbuying, params) {
    const organization = store.state.general.organization;

    return https.put(
      `admin/organizations/${organization.id}/main-groupbuyings/${groupbuying}`,
      {
        ...params,
      }
    );
  },

  deleteGroupbuying(groupbuying) {
    const organization = store.state.general.organization;

    return https.delete(
      `admin/organizations/${organization.id}/main-groupbuyings/${groupbuying}`
    );
  },

  uploadExcel(formData) {
    const organization = store.state.general.organization;
    const url = `admin/organizations/${organization.id}/notifications/action/upload-excel`;

    return https.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getGroupbuyingByProduct({ productID, groupbuyingStatus = [], perPage = 15}) {
    const organization = store.state.general.organization;

    return https.get(
      `admin/organizations/${organization.id}/groupbuyings-by-product/${productID}`,
      {
        params: {
          status: groupbuyingStatus,
          per_page: perPage,
        }
      }
    );
  },

  updateGroupbuyingByProduct(productID, status, params) {
    const organization = store.state.general.organization;

    return https.put(
      `admin/organizations/${organization.id}/groupbuyings-by-product/${productID}`,
      {
        ...params,
        status
      }
    );
  },

  deleteGroupbuyingByProduct(productID, status) {
    const organization = store.state.general.organization;

    return https.delete(
      `admin/organizations/${organization.id}/groupbuyings-by-product/${productID}?status[]=` + status,
    );
  },

  clearGroupbuyingCacheByProduct(productId, params) {
    const organization = store.state.general.organization;

    return https.post(
      `admin/organizations/${organization.id}/groupbuyings-by-product/${productId}/clear-cache`,
      {...params}
    );
  }
};

export default groupbuy;
