<template>
  <div class="branch-table">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <b-button block v-b-toggle.branchTable variant="secondary"
          >共：{{ computedBranches.length }} 筆</b-button
        >
      </b-card-header>
      <b-collapse id="branchTable" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="branches-table d-flex flex-wrap">
            <div
              class="branches-item"
              :key="branch"
              v-for="branch in computedBranches"
            >
              {{ branch }}
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ["branches"],
  computed: {
    computedBranches() {
      return this.branches.map((branch) => {
        // FIXME 無論回傳幾個，後端都要丟 array 比較好
        return typeof branch === "object" && branch !== null
          ? `${branch.branch_code} ${branch.name}`
          : branch;
      });
    },
  },
};
</script>

<style lang="scss">
.branch-table {
  .card {
    border: none;
  }
  .card-header {
    border: none;
  }
}
.branches-item {
  width: 20%;
}
</style>
