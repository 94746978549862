import https from "./https"
import store from "@/store"

const groupType = {
  list() {
    const organization = store.state.general.organization
    return https.get("/admin/organizations/" + organization.id + "/group_types")
  }
}

export default groupType
