<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <div class="p-3">
        <div class="w-50 mx-auto mb-2">
          <b-skeleton-img aspect="1:1"></b-skeleton-img>
        </div>
        <div>
          <b-skeleton width="85%" class="mx-auto mb-1"></b-skeleton>
          <b-skeleton width="55%" class="mx-auto mb-1"></b-skeleton>
          <b-skeleton width="70%" class="mx-auto mb-1"></b-skeleton>
        </div>
      </div>
    </template>

    <div v-if="product" class="product-viewer p-3 flex" no-body>
      <b-carousel
        v-model="imagesIndex"
        :controls="images.length > 1"
        :interval="0"
        no-wrap
      >
        <b-carousel-slide v-for="image in images" :key="image.id">
          <template #img>
            <b-card-img-lazy
              class="mb-2"
              fluid
              :src="image.pic_url"
              alt="產品圖片"
            ></b-card-img-lazy>
          </template>
        </b-carousel-slide>
      </b-carousel>
      <div class="text-center">
        <div>{{ product.title }}</div>
        <div class="small">單價：${{ product.price }}</div>
      </div>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
export default {
  props: ["loading", "product"],

  data() {
    return { imagesIndex: 0 };
  },

  computed: {
    images() {
      if (!this.product) return [];
      return this.product.image_list || this.product.images;
    },
  },
};
</script>

<style lang="scss">
.product-viewer {
  .carousel-inner {
    width: 50%;
    margin: auto;
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  }
}
</style>
